.loader {
    width: 100%;
    height: 100vh;
    .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        right: 50%;
        background-color: #1a8dff;
    }
}
