.landingPage {
  width: 100%;
  padding-right: 5%;
  margin: auto;
  h1 {
    font: normal normal normal 67px/88px Playfair Display;
    color: #001f40;
    padding-bottom: 36px;
    letter-spacing: -4px;

    span {
      color: #0080ff;
    }
  }

  p {
    font: normal normal 400 16px/28px Playfair Display;
    padding-bottom: 49px;
    max-width: 468px;
    width: auto;
  }

  .buttonDiv {
    text-align: left;
    .button {
      font: normal normal 500 16px/28px Playfair Display;
      padding: 16px 32px;
      text-decoration: none;
      background-color: #1a8dff;
      margin: 12px auto;
      color: #ffffff;
      &:hover {
        background-color: #003266;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .landingPage {
    // margin: auto;
    padding: 56px 16px 0 16px;
    height: auto;
    min-height: 75vh;
    h1 {
      font: normal normal normal 51px/56px Playfair Display;
      color: #001f40;
      padding-bottom: 24px;
    }

    p {
      font: normal normal 400 18px/24px Playfair Display;
      padding-bottom: 49px;
      max-width: 468px;
      width: auto;
    }

    .buttonDiv {
      text-align: center;
      padding-bottom: 56px;
      .button {
        font: normal normal 500 16px/28px Playfair Display;
        padding: 16px 32px;
        text-decoration: none;
        background-color: #1a8dff;
        margin: 12px auto;
        color: #ffffff;
        &:hover {
          background-color: #003266;
        }
      }
    }
  }
}
