.homepage {
  height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 0 0 0 120px;
  .left {
    width: 50%;
    background-color: #f2f6fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right {
    width: 50%;
    background-color: #d8e1eb;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 950px) {
  .homepage {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0 !important;
    .left {
      width: 100%;
      background-color: #f2f6fa;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .right {
      width: 100%;
      background-color: #d8e1eb;
      img {
        width: 100%;
        padding-top: 0px;
      }
    }
  }
}
