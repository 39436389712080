.navbar {
  width: 95% !important;
  margin: auto;
  height: 69px;
  border-bottom: 1px solid #bfccd9;
  background-color: #f2f6fa;
  position: sticky !important;

  .brand {
    left: 0;
    position: absolute;
    padding-left: 24px;

    img {
      height: 50px;
    }
  }

  .nav {
    float: right;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto 0 auto auto;

    .links {
      text-decoration: none;
      color: #00264d;
      margin: auto;
      &:hover {
        border-bottom: 1px solid #bfccd9;
      }
    }

    #dropdown {
      color: #00264d !important;
    }
  }
  .span {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .nav {
      display: none;
    }
    .span {
      display: block;
    }
  }
}
