#main {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 69px);
  margin-top: 69px;
  width: 80%;
}

.offcanvas[class*='offcanvas'] {
  background-color: #001f40 !important;
  .btn {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .links {
    text-decoration: none;
    font: normal normal 400 21px/28px Playfair Display;
    color: #ffffff;
    padding: 8px 16px;
  }

  .close {
    width: 18px;
    height: 18px;
    margin: 16px;
  }
  .middleLine {
    width: 80%;
    height: 1px;
    background-color: #ffffff;
    margin-left: 16px;
    margin-top: 24px;
  }

  .icon {
    width: 32px;
    height: 32px;
    margin: 18px;
  }
}

@media screen and (min-width: 992px) {
  #drawer {
    display: none;
  }
  .offcanvas[class='offcanvas'] {
    .btn {
      display: none;
    }
  }
}
