@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Homepage_homepage__25sWw{height:100vh;display:flex;flex-direction:row;padding:0 0 0 120px}.Homepage_homepage__25sWw .Homepage_left__2eSsF{width:50%;background-color:#f2f6fa;display:flex;flex-direction:column;justify-content:center}.Homepage_homepage__25sWw .Homepage_right__h081U{width:50%;background-color:#d8e1eb}.Homepage_homepage__25sWw .Homepage_right__h081U img{width:100%;height:100%;object-fit:cover}@media screen and (max-width: 950px){.Homepage_homepage__25sWw{display:flex;flex-direction:column;height:auto;padding:0 !important}.Homepage_homepage__25sWw .Homepage_left__2eSsF{width:100%;background-color:#f2f6fa;display:flex;flex-direction:column;justify-content:center}.Homepage_homepage__25sWw .Homepage_right__h081U{width:100%;background-color:#d8e1eb}.Homepage_homepage__25sWw .Homepage_right__h081U img{width:100%;padding-top:0px}}
.LandingPage_landingPage__3gILx{width:100%;padding-right:5%;margin:auto}.LandingPage_landingPage__3gILx h1{font:normal normal normal 67px/88px Playfair Display;color:#001f40;padding-bottom:36px;letter-spacing:-4px}.LandingPage_landingPage__3gILx h1 span{color:#0080ff}.LandingPage_landingPage__3gILx p{font:normal normal 400 16px/28px Playfair Display;padding-bottom:49px;max-width:468px;width:auto}.LandingPage_landingPage__3gILx .LandingPage_buttonDiv__1LeMd{text-align:left}.LandingPage_landingPage__3gILx .LandingPage_buttonDiv__1LeMd .LandingPage_button__35kgQ{font:normal normal 500 16px/28px Playfair Display;padding:16px 32px;text-decoration:none;background-color:#1a8dff;margin:12px auto;color:#fff}.LandingPage_landingPage__3gILx .LandingPage_buttonDiv__1LeMd .LandingPage_button__35kgQ:hover{background-color:#003266}@media screen and (max-width: 950px){.LandingPage_landingPage__3gILx{padding:56px 16px 0 16px;height:auto;min-height:75vh}.LandingPage_landingPage__3gILx h1{font:normal normal normal 51px/56px Playfair Display;color:#001f40;padding-bottom:24px}.LandingPage_landingPage__3gILx p{font:normal normal 400 18px/24px Playfair Display;padding-bottom:49px;max-width:468px;width:auto}.LandingPage_landingPage__3gILx .LandingPage_buttonDiv__1LeMd{text-align:center;padding-bottom:56px}.LandingPage_landingPage__3gILx .LandingPage_buttonDiv__1LeMd .LandingPage_button__35kgQ{font:normal normal 500 16px/28px Playfair Display;padding:16px 32px;text-decoration:none;background-color:#1a8dff;margin:12px auto;color:#fff}.LandingPage_landingPage__3gILx .LandingPage_buttonDiv__1LeMd .LandingPage_button__35kgQ:hover{background-color:#003266}}
#main{display:flex;flex-direction:column;height:calc(100vh - 69px);margin-top:69px;width:80%}.offcanvas[class*=offcanvas]{background-color:#001f40 !important}.offcanvas[class*=offcanvas] .btn{width:24px;height:24px;position:absolute;right:24px;top:24px}.offcanvas[class*=offcanvas] .links{text-decoration:none;font:normal normal 400 21px/28px Playfair Display;color:#fff;padding:8px 16px}.offcanvas[class*=offcanvas] .close{width:18px;height:18px;margin:16px}.offcanvas[class*=offcanvas] .middleLine{width:80%;height:1px;background-color:#fff;margin-left:16px;margin-top:24px}.offcanvas[class*=offcanvas] .icon{width:32px;height:32px;margin:18px}@media screen and (min-width: 992px){#drawer{display:none}.offcanvas[class=offcanvas] .btn{display:none}}
.Header_navbar__359Kr{width:95% !important;margin:auto;height:69px;border-bottom:1px solid #bfccd9;background-color:#f2f6fa;position:-webkit-sticky !important;position:sticky !important}.Header_navbar__359Kr .Header_brand__1Eas8{left:0;position:absolute;padding-left:24px}.Header_navbar__359Kr .Header_brand__1Eas8 img{height:50px}.Header_navbar__359Kr .Header_nav__LNsaU{float:right;width:50%;display:flex;flex-direction:row;justify-content:space-between;margin:auto 0 auto auto}.Header_navbar__359Kr .Header_nav__LNsaU .Header_links__3Aw6q{text-decoration:none;color:#00264d;margin:auto}.Header_navbar__359Kr .Header_nav__LNsaU .Header_links__3Aw6q:hover{border-bottom:1px solid #bfccd9}.Header_navbar__359Kr .Header_nav__LNsaU #Header_dropdown__8OMp8{color:#00264d !important}.Header_navbar__359Kr .Header_span__3oJZ_{display:none}@media screen and (max-width: 992px){.Header_navbar__359Kr{display:flex;flex-direction:row;justify-content:space-between}.Header_navbar__359Kr .Header_nav__LNsaU{display:none}.Header_navbar__359Kr .Header_span__3oJZ_{display:block}}
.App_app__2ziFi{background-color:#f2f6fa;font-family:"Playfair Display SC",serif}
.Loader_loader__2hZcM{width:100%;height:100vh}.Loader_loader__2hZcM .Loader_spinner__1BGHY{position:absolute;top:50%;left:50%;right:50%;background-color:#1a8dff}
